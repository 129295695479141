import React, {useContext} from 'react'
import {MyContext} from '../../login/MyContext'
import LogoLogin from '../../assets/views/robot.gif';

function Productos() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
      <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-success">
<div class="inner">
<center><h4>SERVIDOR</h4></center>
<div class="ribbon" style={{background: " 0% 0% / cover #059BB6", textAlign:"center"}}></div>
<hr/>
<p><b>Óptima</b> velocidad del servidor</p> 
<p>Hosting dedicado  - <b>ilimitado</b><br/>
RAM 6GB  - <b>ocupado: 28%</b> <br/>
Procesador 4 cores - <b>ocupado: 43%</b><br/>
500 GB SSD  - <b>ocupado: 20%</b><br/> 
Banda ancha  - <b>ocupado: 29%</b><br/>
Nodos - <b>ilimitado</b><br/>
Socket - <b>ilimitado</b><br/>  
<p> Cerificado SSL pyme : - <b>ilimitado</b></p></p>
</div>
<div >

</div>
{/*<p  class="small-box-footer">
$200.000 COP / <i class="fas fa-arrow-circle-up"></i>
    </p> */}
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-success">
<div class="inner">
<center><h4>IA</h4></center>
<div class="ribbon" style={{background: " 0% 0% / cover #059BB6", textAlign:"center"}}>Te quedan 280 días de uso</div>
<hr/>
<p><b>IA</b> actual equivale a 15 programadores. <br/>
<center><img width="90px" src={LogoLogin} /></center></p>
</div>
<div >

</div>
<p  class="small-box-footer">
$1.000.000 COP / <i class="fas fa-arrow-circle-up"></i>
</p>
</div>

</div>

{/*
<div class="col-lg-3 col-12">

<div class="small-box bg-success">
<div class="inner">
<center><h4>DOMINIOS EN USO</h4></center>
<hr/>
<p>maxpaint.com.co <br/>
<div class="ribbon" style={{background: " 0% 0% / cover #059BB6", textAlign:"center"}}>vence el 10 de mayo del 2023</div>
</p>
</div>
<div >

</div>
<p  class="small-box-footer">
<i class="fas fa-arrow-circle-up"></i>
</p>
</div>

</div> */}




{/*
<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h4>ANDROID / IOS</h4></center>
<hr/>
<p>Cuenta Android <br/>
<div class="ribbon" style={{background: " 0% 0% / cover #03A445", textAlign:"center"}}>vence el 3 de mayo del 2023 <br/>
$80.000 COP</div>

</p>
</div>
<div >

</div>
<p  class="small-box-footer">
<i class="fas fa-arrow-circle-up"></i>
</p>
</div>

</div> */}



        
   {  /* <div className="col-md-12">

          <div className="callout callout-info">
              <h5><i className="fa fa-shopping-cart"></i> Shoping</h5>
              
              <p>• Los dominios de cada paquete tienen una vigencia de un año despues de la activación. <br/>
              • Cada dominio se activaría cuando el cliente quiera utilizarlo, de resto estarán archivados. <br/>
              • Todos los dominios vienen con certificado SSL.   </p>
             
            
             


          
            </div>

            
       
    </div>  */}

         
        </div> 
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Productos
