import React,{useState, useEffect, useContext} from 'react';
import {MyContext} from '../../login/MyContext';
import {FaCheckCircle, FaTrash, FaPen } from 'react-icons/fa'; // npm i react-icons
import axios from 'axios';

function Esquemas() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [lista, setLista] =  useState([]);
    const [nombre, setNombre] = useState('');
    const [precio, setPrecio] = useState('');
    const [fecha, setFecha] = useState('');

    const [imagen2, setImagen2] = useState(null);
    const [logos, setLogos] = useState(null);
    const [id, setId] = useState(''); 
    const [bandera, setBandera] = useState(true);

    useEffect(() => {
      getProductos();
    },[])


    async function getProductos(){
      const res = await axios.get('https://ai-od.com/pilar/esquema/');
      setLista(res.data) 
      console.log(res.data)
  } 


  
  /*async function addProducto() {
      const obj = {nombre, precio,imagen2};
      const res = await axios.post('https://ai-od.com/esquema/', obj);
      console.log(res.data);
 
      getProductos();
  }*/


 async function addProducto(e) {
   // e.preventDefault();
    let fd = new FormData() 
    fd.append("imagen2", imagen2)
    fd.append("nombre", nombre)
    fd.append("precio", precio)
    const res = await axios.post('https://ai-od.com/pilar/esquema/', fd);
    console.log(res.data)
    getProductos();
 
   } 

  
  async function UpdateProducto(e) {
      const obj = {id, nombre, precio};
      const res = await axios.put('https://ai-od.com/pilar/esquema/', obj);
      console.log(res.data)
      getProductos();
  
  }
  
  function addUpdate(e) {
      e.preventDefault(); 
      bandera? addProducto():UpdateProducto();
      limpiarEstado();
  }
  
  async function deleteProducto(id){ 
   
     if(window.confirm('Quieres eliminar?')){
        const res = await axios.delete('https://ai-od.com/pilar/esquema/?id='+id);
        getProductos();
        console.log(res.data)
    }
  } 
  
  async function getProducto(id){
      const res = await axios.get('https://ai-od.com/pilar/esquema/?id='+id);
      setId(res.data.id);
      setNombre(res.data.nombre);
      setPrecio(res.data.precio);
      setFecha(res.data.fecha);

      setFecha(res.data.imagen2);
      setFecha(res.data.logos);
      setBandera(false)
  } 
  
  function limpiarEstado(){
    setId('');
    setNombre('');
    setPrecio('');
    setBandera(true);

    setImagen2();
    setLogos();
  }

    if(isAuth)
    {
    return (
      <div className="content-wrapper">


       <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>

    <section className="content">

    <div className="container-fluid">
        
        <div className="row">

        <div className="col-md-12">

        <div className="callout callout-info">

        <div class="col-md-3 col-sm-6 col-12">
<div class="info-box">
<span class="info-box-icon bg-warning"><i class="far fa-copy"></i></span>
<div class="info-box-content">
<span class="info-box-text">GUÍA ESQUEMA</span>
<span class="info-box-number"><a href='https://ai-od.com/diego/esquema/Esquema.docx'>DESCARGAR</a></span>
</div>

</div>

</div>

<div class="card card-primary">
<div class="card-header">
<h3 class="card-title">ESQUEMA</h3>
</div>


<form>
<div class="card-body">
<div class="form-group">
<label for="exampleInputEmail1">Título</label>

<input type="text" placeholder="Título proyecto" className="form-control"
                 onChange={(e) => setNombre(e.target.value)}
                 value={nombre}/>
</div>

<div class="form-group">
<label for="exampleInputFile">Subir esquema</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-warning'

onChange={(e) => setImagen2(e.target.files[0])}/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>
</div>
</div>

<div class="form-group">
<label for="exampleInputFile">Imagenes</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-success' multiple
 accept="image/*"
/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>
</div>
</div>

<div class="form-group">
<label for="exampleInputEmail1">Dominio - proyecto</label>
<input type="text" placeholder="ai-od.miproyecto.com" className="form-control"
                 onChange={(e) => setPrecio(e.target.value)} 
                 value={precio}/>
</div>


{
  /*
<div class="form-group">
<label for="exampleInputEmail1">imagen2</label>
<input type="text" placeholder="ai-od.miproyecto.com" className="form-control"
                 onChange={(e) => setImagen2(e.target.value)} 
                 value={imagen2}/>
</div>
*/ }


</div>

<div class="card-footer">

<button disabled className="btn btn-primary" 
               onClick={(e) => addUpdate(e)} >
                 {bandera?"CREAR ESQUEMA":"Edit"}
                </button> 
</div>
<br/>


<div class="card card-warning">
<div class="card-header">
<h3 class="card-title ">ESQUEMAS CREADOS</h3>
<div class="card-tools">
<ul class="pagination pagination-sm float-right">

</ul>
</div>
</div>

<div class="card-body p-0">
<table class="table">
<thead>
{/*<tr>
<th style={{width: "10px"}}>#</th>
<th>Esquema</th>

<th>Validado</th>
<th style={{width: "40px"}}>%</th>
    </tr> */}
</thead>
{<tbody>
{/*<center>  <p>El sistema SSL de su servidor no tiene suficiente recurso para funcionar, actualce su servidor para continuar </p></center>*/}
{/*
{ lista.map( producto  => (
             <div  key={producto.id}>
               <tr>
               <td></td>
               <th style={{width: "10px"}}>{producto.nombre}</th>
            
               <td style={{width: "40px"}}> <b>Validando </b>  (de 2 a 8 horas) </td>
               <td className='btn btn-warning'>En proceso</td>
               
               <td>{producto.precio}</td>
               <td>{producto.fecha}</td>
               <td><span class="badge bg-primary"> <b>IA VERIFICANDO PROYECTO</b></span></td>
              
               </tr>
            
             </div>         
          ))}  
*/}
</tbody> } 

</table>
</div>

</div>

</form>







    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
</div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Esquemas
