import React, {useContext, useState, useEffect} from 'react';
import {MyContext} from '../../login/MyContext';
import axios from 'axios';

function Proyectos() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [modulo, setModulo] = useState('');
    const [lista, setLista] =  useState([]);
    const [imagen2, setImagen2] = useState(null);
    const [bandera, setBandera] = useState(true);
    const [id, setId] = useState(''); 


    useEffect(() => {
      getProductos();
    },[])


    async function getProductos(){
      const res = await axios.get('https://ai-od.com/pilar/proyectos/');
      setLista(res.data) 
      console.log(res.data)
  } 



    async function addProducto(e) {
      // e.preventDefault();
       let fd = new FormData() 
       fd.append("imagen2", imagen2)
       const res = await axios.post('https://ai-od.com/pilar/proyectos/', fd);
       console.log(res.data)
       getProductos();
    
      } 
   
     
     async function UpdateProducto(e) {
         const obj = {id};
         const res = await axios.put('https://ai-od.com/pilar/proyectos/', obj);
         console.log(res.data)
         getProductos();
     
     }
     
     function addUpdate(e) {
         e.preventDefault(); 
         bandera? addProducto():UpdateProducto();
    
     }


     async function getProducto(id){
      const res = await axios.get('https://ai-od.com/pilar/proyectos/?id='+id);
      setId(res.data.id);
      setModulo(res.data.modulo);
      
      setBandera(false)
  } 

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">

 

        
          <div className="col-md-12">

          <div className="callout callout-info">

         

<div class="card card-warning">
<div class="card-header">
<h3 class="card-title">PROYECTOS ACEPTADOS</h3>
</div>


<form>


<div class="card-body">



<div class="col-sm-6">

<div class="form-group">
<label>Proyectos</label>
<select class="form-control">
<option>parqueoenvia.co</option>
</select>
</div>
</div>


<h5> Inicio de proyecto - 16 de junio 2022</h5>
<hr />
<div class="form-group">
<label for="exampleInputEmail1">Modulo1  - administrador </label><br />
-	Crear dos  super administradores , uno que se llame delegado y otro Pilar <br />
-	Crear modulo para crear puntos de ventas <br />
-	Crear modulo para crear usuarios operadores <br />
-	Crear modulo para crear usuarios supervisores <br />
-	Crear modulo para crear usuarios autoridades <br />
-	Crear modulo para crear usuarios Visores <br />
-	Crear modulo para crear usuarios publicidad <br />
-	Crear modulo para crear usuarios asignador de minutos


</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 23 de junio del 2022 - 9:00 PM

<br/>
Observaciones: usuarios administrativos: pilar@parqueoenvia.co - clave:12345678 / admin@parqueoenvia.co clave:123456789
<hr />

<div class="form-group">
<label for="exampleInputEmail1">Modulo1  -  Registro de clientes </label><br />
-	Los clientes se podrán registrar e iniciar sesión y aceptar el habeas data<br />
-	Ver salados disponibles<br />
-	Ver saldos gastados<br />


</div>
 el 28 de junio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo1  -  Usuario punto de ventas</label><br />
-	Los usuarios de ventas podrán iniciar sesión y agregar saldos a los clientes<br />
-	Podrán visualizar las recargar que se le haga a cada cliente<br />



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 29 de junio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo1  -  Usuario operadores</label><br />
-	Los usuarios operadores  podrán iniciar sesión <br />
-	Podrán generar recargas dependiendo el tipo de vehículo <br />
-	Podrán reportar los vehículos 




</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 30 de junio del 2022 - 9:00 PM

<hr />



<div class="form-group">
<label for="exampleInputEmail1">Modulo1  -  Usuario supervisores</label><br />
-	Los usuarios supervisores  podrán iniciar sesión <br/>
-	Podrán visualizar a cada operario   





</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 1 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo1  -  Usuario autoridades</label><br />
-	Los usuarios autoridades podrán iniciar sesión <br />
-	Podrán visualizar los reportes enviados por los operarios



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 3 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo1  - Usuario visores</label><br />
-	Los usuarios visores podrán iniciar sesión <br/>
-	Podrán visualizar lo mismo que el administrador pero solo podrá ver y no modificar nada



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 5 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo1  - Usuario publicidad</label><br />
-	Los usuarios publicidad podrán iniciar sesión <br/>
-	Podrán subir los banner que se van rotando y se van a ver en la web de los clientes



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 6 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo1  - Usuario asignador de minutos</label><br />
-	Los usuarios asignador de minutos podrán iniciar sesión <br/>
-	Podrán subir los banner que se van rotando y se van a ver en la web de los clientes




</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 7 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - Administrador</label><br />
-	El administrador podrá crear todas las zonas azules con sus respectivas  bahías <br/>




</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 8 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - clientes</label><br />
-	Los clientes podrán solicitar zonas azules a utilizar<br/>
-	Podrá visualizar un registro de todas sus recargas <br/>
-	El cliente tendrá la opción de devolución del dinero pero solo estará activa si el administrador lo determina





</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 9 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - usuario operadores</label><br />
-	Tendrán un módulo observaciones y recomendaciones supervisores. <br/>
-	Tendrá un modulo el cual le indique y solo lo deje trabajar al operario en los horarios que el administrador indique y la zonas azules que le indiquen <br/>
-	Podrá visualizar las zonas y las bahías libres para asignar el vehículo correspondiente. <br/>
-	Podrá cancelar los reportes a las autoridades .






</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 9 de julio del 2022 - 9:00 PM

<hr />




<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - usuario supervisores</label><br />
-	Tendrán un módulo observaciones y recomendaciones supervisores.<br />
-	Podrán visualizar todas las zonas azules y celdas disponibles o utilizadas en tiempo real con la información de cada operador<br />
-	Podrán observar los cierres de jornada de cada operador<br />
-	Deberán tener un espacio para poder hacer llamados de atención o mejores observaciones a los operadores.







</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 9 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - usuario autoridades</label><br />
-	Podrán visualizar todas las zonas azules y celdas disponibles o utilizadas en tiempo real con la información de cada operador.



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />



<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - usuario visores</label><br />
-	Podrá enviarle mensajes a los operadores, supervisores y autoridades 



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo2  - usuario asignador de minutos</label><br />
- Tendrá reporte de todas las bolsas de minutos asignadas y cuanto han gastado.



</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo3  - administrador</label><br />
-	Podrá generar reportes  detallados en excel de todos los tipos de usuarios de la plataforma <br />
-	Podrán generar reportes detallados de todo lo acumulado y saldos pendientes  <br />
-	Podrá visualizar todo lo que hace los operadores, las autoridades y puntos de ventas <br />




</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />



<div class="form-group">
<label for="exampleInputEmail1">Modulo3  - activación datafono</label><br />
-	Se verificara que la api del datafono conecte con la plataforma. 




</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />



<div class="form-group">
<label for="exampleInputEmail1">Modulo3  - usuario operadores</label><br />
-	Al realizar una recarga modo datafono deberá asignar la información para que pueda el administrador o la persona encargada de validar los pagos de los operadores. 




</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo3  - usuario punto de venta</label><br />
-	Al realizar una recarga modo datafono deberá asignar la información para que pueda el administrador o la persona encargada de validar los pagos de los puntos de ventas.


</div>
<button  class="btn btn-success" disabled>VER AVANCE</button> el 10 de julio del 2022 - 9:00 PM

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo4  - creación de api</label><br />
-	Se generara una api de conexión para clientes terceros la puedan conectar su software con la plataforma parqueo envía.

</div>
<a  class="btn btn-success" href="https://portal.parqueoenvia.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>PLATAFORMA WEB FINALIZADA</a> el 10 de julio del 2022 - 9:00 PM <br/><br/>
<a  class="btn btn-success" href="https://parqueoenvia.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>PLATAFORMA MOVIL FINALIZADA - Ingresar desde un celular - https://parqueoenvia.co/</a>
<hr />

{/*<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 9 de noviembre del 2021 <br/><br/>*/}
{/*<a  class="btn btn-success" href="https://maxpain.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 9 de mayo del 2022 - 9:00 PM
 
 <hr />



          
{/*
<hr />

<div class="form-group">
<label for="exampleInputEmail1">Modulo 2:</label><br />
<mark style={{ background: "#39DF08"}}>•	Que tenga boton de whatsapp que pregunte ¿ QUE NECESITA ?con numero +34625825342 <br />
•	Nombre del logo “milogomaxpaint” <br />
•	Que tenga botones  que diga contacta con nosotros y puedan llenar dato como nombre , correo de contacto y numero de contacto y descripción de trabajo a cotizar <br />
•	Que tenga parte inferior de el logo  imágenes con la siguiente información expertos en pintados de parkings de rotación la imagen sea de parkins , expertos en pulido litio adjuntar foto de suelo pintado , expertos en protección anticorrosiva adjunte imagen de tubos , expertos en pintura decorativa  <br />
<br/>
•	Modificar la información de contacto de el encabezado y de la totalidad de la página por teléfono de contacto 625825342-665128854<br />
•	Correo electrónico maxpaint03@gmail.com <br />
• Dirección Carrer de Tortosa, 122, 08918 Badalona, Barcelona<br />
•	Modificar de los  botones de servicios , trabajos realizados, blog y contacto  la palabra tecnipin por palabra maxpaint <br />
•	Información de contacto por número 625825342-665128854 <br />
• Correo electrónico  maxpaint03@gmail.com <br />
• dirección Carrer de Tortosa, 122, 08918 Badalona, Barcelona<br />
• Tanto como para el encabezado como para el final de la página <br />
• link de ubicación google https://goo.gl/maps/UqURb4Xfp5chL3GA9<br />
• dirección de domicilio Carrer de Tortosa, 122, 08918 Badalona, Barcelona<br />
• Modificar color principal rojo por el color azul <br />
• modificar en botón de trabajos realizados en la información de contacta con nosotros  <br />
• Número de contacto 625825342 botón de enlace WhatsApp <br />
• Correo electrónico maxpaint03@gmail.com </mark><br />

</div> */}

{/*<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 9 de noviembre del 2021 <br/><br/>*/}
{/*<a  class="btn btn-success" href="https://maxpain.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 13 de mayo del 2022 - 9:00 PM
    */}

{/*<br/><br/>{/*
 <h6><b>Observaciones</b></h6>
  <p>hola "diego" los puntos para el avance ya estan completados, por favor en un documento de word enviar los puntos si desea cambiar algo de lo ya actualizado, y tambien enviar los siguientes puntos que se detectaron:  
  <br/><mark>
  •	facebook e instagram <br/> 
 </mark> <br/></p>
    */ }
  

              


{/*<hr />

<div class="form-group">
<label for="exampleInputEmail1">Modulo 3:</label><br />
<mark style={{ background: "#39DF08"}}>• Al dar clic en los números telefónicos de la totalidad de la página  sean enviados a enlace de llamada del número principal 625825342 y si dan clic en el número de oficina 665128854 sean enviados a enlace de llamadas automáticamente cada número telefónico por aparte tanto como el principal y aparte el número de oficina. </mark>  <br />
<br/> 
<a  class="btn btn-success" href="https://maxpain.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 15 de mayo del 2022 - 11:30 AM
</div>






  <hr /> */}
{/*
<div class="form-group">
<label for="exampleInputEmail1">Modulo 4:</label><br />
<mark style={{ background: "#39DF08"}}>
•Modificar todas las imágenes de la página por imágenes similares de internet <br/>

•Modificar información de quienes somos del menú inicial y del menú de quienes somos y reemplazarla por el siguiente texto En Maxpaint estamos especializados en pinturas industrial y decorativa.. Nuestro catálogo de servicios es muy extenso gracias a nuestras múltiples especialidades como el color, la decoración, etc. Además, contamos con interioristas profesionales que estarán encantados de aconsejarte en todo lo que necesites.

El cliente siempre es nuestra prioridad, así que nos gusta poder conocer cada detalle del proyecto que tiene en su mente para poder aportar toda nuestra calidad y que obtenga exactamente lo que esperaba de nosotros. Esta siempre ha sido uno de nuestros pilares
<br />
• quitar botón que dice saber más <br/>
• quite la información que dice algunos de nuestros clientes quitar todos los logos solo dejar el logo de seat<br/>
• quitar información de algunos de nuestros clientes quitar todos los logos <br/>
• quitar de la parte de debajo de la totalidad de la página la parte que dice certificados quitar logo de tuv Austria <br/>
• en la parte de contacto que dice nombre email y escribe su mensaje  esa información que escriban ay  al darle  enviada  los datos sean enviados al correo maxpaint03@gmail.com <br/>
• quitar del menú la opción blog <br/> </mark>




<br/>

<a  class="btn btn-success" href="https://maxpaint.com.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> 24 a 48 horas - 22 de mayo del 2022 - 7:02 PM

<br/>
<b>obervaciones:</b> Hola "diego" los formularios de contacto  se restructuraron para funcionaran por que en "www.tecnipin.com" trabajan una api de terceros, por lo tanto se <b>incremento 48 horas mas de trabajo </b>

<br/>

</div>



           




<hr /> */}

{/*<div class="form-group">
<label for="exampleInputEmail1">Modulo 5:</label><br />
{/*<mark style={{ background: "orange"}}>
<b>Observaciones 2</b> <br/>

Hola "diego", no se detectaron las imagenes, suba las imagenes en un archivo comprimido en .rar o .zip donde cada imagen lleve el nombre correspondiente como hizo referencia.
  </mark>*/}
{/*<mark style={{ background: "#39DF08"}}>

• quitar del menú el botón quienes somos <br />
• eliminar del inicio la totalidad de las imágenes de oficinas y de servicios <br />
• menú de contacto eliminar imagen donde dice contacto necesitas más información contacta con nosotros y remplazar por imagen de nombre contacto<br />
• menú de inicio retirar imagen y frase que dice expertos en pintado de parkings de rotación y remplazar por 
 Frase pintura decorativa agregar imagen piso 1 sin marca de agua <br />
• Menú inicio retirar imagen y frase que dice expertos en pulido de litio y remplazar por frase pintura de fachada y agregar imagen fachada sin marca de agua <br />
• Menú de inicio retirar imagen y frase que dice expertos en protección anticorrosiva y remplazar por frase pintura anticorrosiva y agregar imagen estructuras metálicas sin marca de agua<br /> 
• Menú inicio retirar imagen y frase expertos en protección pasiva contraincendios y remplazar por pintura de suelos y agregar imagen suelos 1 sin marca de agua <br />
• Menú inicio retirar imagen y frase expertos en impermeabilización y remplazar por frase pintura de parkings y señalización agregar imagen 18 sin marca de agua <br />
• Menú inicio retirar imagen y frase expertos en pintura decorativa y remplazar por frase pavimentos Multicapa agregar imagen  pavimentos sin marca de agua <br />
• menú de inicio remplazar información e imágenes de los servicios por 
Servicios<br />
• Pintura decorativa
En Maxpaint ofrecemos una excelente carta de colores brindando a nuestros clientes lograr embellecer interiores y exteriores personalizando lugares con los mejores acabados 
Agregar imagen piso 1   
<br/>
• Pintura de suelos 
La pintura para suelos cubre y protege una amplia diversidad de suelos: de hormigón, cemento, cerámica, suelos de madera o laminados, piedra… Además de mejorar su estética, permite hacer resistentes los suelos al tránsito intenso, al ser válido tanto en interiores como exteriores.
Agregar imagen  suelos 1 
<br/>
• Pintura de estructuras industriales
Conserva y restablece todo tipo de estructuras metálicas de depósitos y fabricas con protección anticorrosiva 
Agregar imagen estructuras 1 
<br/>

• botón de inicio ver mas servicios al dar clic transportar a el menú de servicios <br/>
• modificar información de botón del menú servicios por la siguiente información <br/>
Título servicios
 agregar imagen servicios sin marca de agua  y quitar frase descubre lo que Maxpaint puede hacer por si empresa 
Pintura decorativa 
En Maxpaint ofrecemos una excelente carta de colores brindando a nuestros clientes lograr embellecer interiores y exteriores personalizando lugares con los mejores acabados 
Agregar imagen piso 1  
<br/>
Pintura de fachadas 
Se realizan pinturas de cualquier tipo de fachadas con pintura plástica o esmaltadas 
Con los mejores acabados 
Agregar imagen fachada 
<br/>
Pintura de suelos 
La pintura para suelos cubre y protege una amplia diversidad de suelos: de hormigón, cemento, cerámica, suelos de madera o laminados, piedra… Además de mejorar su estética, permite hacer resistentes los suelos al tránsito intenso, al ser válido tanto en interiores como exteriores.
Agregar imagen suelos 
<br/>
Pintura anticorrosiva 
Se pintan todo tipo de estructuras depósitos y fábricas con pintura anticorrosiva que conserve y mejore su aspecto y durabilidad de el elemento pintado 
Agregar imagen estructuras metálicas 
<br/>
Pintado de parkings 
Se realiza preparación de pavimentos pintados y señalización vial con los mejores materiales Para ara lograr su mayor duración 
Agregar imagen parkings
<br/>
Pavimentos 
Somos especialistas en autonivelantes,multicapas antiestáticos y ciclos de pintado con los mejores materiales del mercado 
Agregar imagen pavimentos 
<br/>
• modificar información en su totalidad  del botón menú trabajos realizados por la siguiente 
Título trabajos realizados 
Agregar imagen trabajos realizados 
Pintura decorativa 
Agregar imágenes 1,2
Pintura fachadas 
Agregar imágenes 5,6 
Pintura anticorrosiva
Agregar imágenes 9,10,

Pintura de suelos 
Agregar imágenes 13,14

Pintura parking 
Agregar imágenes 17,18
Señalización 
Agregar Imagen  21,22
Pintura estructuras industriales 
Agregar imágenes 32,33 

 <br/>• modificar de el botón del menú contacto la imagen del título y reemplazarla por imagen contacto y quitar la pregunta ¿ necesitas más información?  Y solo dejar la frase contacta aquí 

</mark>

<br/>
<br/>

<a  class="btn btn-success" href="https://maxpaint.com.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 31 de mayo del 2022 a las 9:00 PM

{/*<a  class="btn btn-success" href="https://maxpaint.com.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> 24 a 48 horas - 22 de mayo del 2022 - 7:02 PM */}

{/*<b>obervaciones:</b> Hola "diego" los formularios de contacto  se restructuraron para funcionaran por que en "www.tecnipin.com" trabajan una api de terceros, por lo tanto se <b>incremento 48 horas mas de trabajo </b>*/}

{/*

</div>

*/}
{/*<div class="form-group">
<label for="exampleInputFile">Subir actualización</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-warning'
onChange={(e) => setImagen2(e.target.files[0])}
/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>


</div>
</div>  */}
{/*
<br/>

<div class="card card-warning">
<div class="card-header">
<h3 class="card-title ">ACTUALIZACIONES CREADAS</h3>
<div class="card-tools">
<ul class="pagination pagination-sm float-right">

</ul>
</div>
</div>

<div class="card-body p-0">
<table class="table">
<thead>

</thead>

{<tbody>

{ lista.map( proyectos  => (
             <div  key={proyectos.id}>
               <tr>
               <td></td>
               <th style={{width: "10px"}}>{proyectos.modulo}</th> */}
            
               {/*<td style={{width: "40px"}}> <b>Validando </b>  (de 2 a 8 horas) </td>*/}
          {/*     <td className='btn btn-warning'>en proceso</td> */}
               
               
           {/*  <td>{proyectos.fecha}</td> */}
             {/*  <td><span class="badge bg-primary"> <b>IA VERIFICANDO ACTUALIZACION</b></span></td> */}
            {/* <td><span class="badge bg-primary"> <b>IA VERIFICANDO ACTUALIZACION</b></span></td>
               </tr>
            
             </div>         
          ))}  

</tbody> } 

</table> 
</div>

</div>   

              */}     




{/*<button type="submit" class="btn btn-primary" disabled>ACTUALIZAR</button> */}
{/*<button  className="btn btn-primary" 
               onClick={(e) => addUpdate(e)} >
                 {bandera?"ACTUALIZAR":"Edit"}
</button>  */}

{/*
<div class="form-group">
<label for="exampleInputEmail1">Modulo 2:</label>
<b>pagina web - Fecha de entrega el 25 de noviembre a las 7:00 pm / 2021</b> <br />
•	Creación de modulo “cronograma” <br />
•	En el modulo “cronograma” contiene los siguientes submodulos: hora de entrada, hora de salida, tanques <br />
•	En “horas de entrada” que contenga los siguientes campos:  hora de ingreso, cedula de ingreso, motivo de ingreso. <br />
•	En “horas de salida” que contenga los siguientes campos: hora de salida, cedula de ingreso, motivo de ingreso <br />
•	En “tanques” que contenga los siguientes campos: numero de tanque , que me cargue el modulo de hora de salida los usuarios registrados para seleccionar uno.
 <br />

</div>

<button type="submit" class="btn btn-danger" disabled>CORREPCIÓN O ADICIÓN</button> el 25 de noviembre del 2021 <br/><br/>
•	Agregar logo "logomenu" centrado  en la parte superior de los submodulos del cronograma <br /> <br/>

<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 27 de noviembre del 2021 <br/><br/>
<button type="submit" class="btn btn-warning" disabled>VER AVANCE</button> el 27 de noviembre del 2021

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo 3:</label>
<b>pagina web - Fecha de entrega el 2 de diciembre a las 7:00 pm / 2021</b> <br />
•	Creación de modulo “trabajador del mes” <br/>
•	En el modulo “trabajador del mes” contiene los siguientes submodulos: estadística, trabajador del mes.<br/>
•	En “estadística” que cargue todos los trabajadores del mes con los siguientes campos:  fecha de trabajador del mes, nombre trabajador del mes, cedula trabajador del mes.<br/>
•	En “trabajador del mes” que contenga los siguientes campos: que me cargue los usuarios para seleccionarlos del modulo de operarios.<br/>
 <br />

</div>

<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 2 de diciembre del 2021 <br/><br/>
<button type="submit" class="btn btn-warning" disabled>VER AVANCE</button> el 2 de diciembre del 2021

<hr />




<div class="form-group">
<label for="exampleInputEmail1">Modulo 4:</label>
<b>pagina web - Fecha de entrega el 13 de diciembre a las 7:00 pm / 2021</b> <br />
•	Creación de modulo “mis tareas” <br/>
•	En el modulo “mistareas” contiene los siguientes campos: tareas, cargar los usuarios del modulo de operarios y que se les pueda asignar una tarea.<br/>

</div>

<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 13 de diciembre del 2021 <br/><br/>
<button type="submit" class="btn btn-warning" disabled>VER AVANCE</button> el 13 de diciembre del 2021

<hr />

<p>Clic - Página 2 </p>

*/}
</div>



<div class="card-footer">

{/*<div class="col-md-3 col-sm-6 col-12">
<div class="info-box bg-gradient-success">
<span class="info-box-icon"><i class="far fa-thumbs-up"></i></span>
<div class="info-box-content">
<span class="info-box-text">Proyecto</span>
<span class="info-box-number">48 días de trabajo</span>
<div class="progress">
<div class="progress-bar" style={{width: "100%"}}></div>
</div>
<span class="progress-description">
100% Finalizado


</span>

</div>
<button type="submit" class="btn btn-primary">VER PROYECTO FINALIZADO</button>

</div>

</div> */}

</div>


</form>
</div>
             


          
            </div>

            
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Proyectos
